// extracted by mini-css-extract-plugin
export var articleContainer = "index-module--articleContainer--92426";
export var articleContainerBody = "index-module--articleContainerBody--931cd";
export var articleContainerFooter = "index-module--articleContainerFooter--6e265";
export var author = "index-module--author--2ca7f";
export var beta = "index-module--beta--80f65";
export var blackButton = "index-module--blackButton--64749";
export var blueButton = "index-module--blueButton--f2528";
export var bottomStars = "index-module--bottomStars--6abe0";
export var button = "index-module--button--57f10";
export var category = "index-module--category--170a1";
export var codeRenderer = "index-module--codeRenderer--e5bee";
export var communitySection = "index-module--communitySection--a93ad";
export var communitySectionTitle = "index-module--communitySectionTitle--3cdb0";
export var debugAsCode = "index-module--debugAsCode--a77d2";
export var environmentsButtons = "index-module--environmentsButtons--b223c";
export var environmentsSection = "index-module--environmentsSection--95ee6";
export var featuresSection = "index-module--featuresSection--5d6e9";
export var graph = "index-module--graph--d69be";
export var headerIcon = "index-module--headerIcon--debcd";
export var heroSection = "index-module--hero-section--135f0";
export var home = "index-module--home--bd171";
export var illustration = "index-module--illustration--98d21";
export var kubernetesNative = "index-module--kubernetesNative--2de15";
export var large = "index-module--large--a721c";
export var largeIllustration = "index-module--large-illustration--8a892";
export var learnMoreButton = "index-module--learn-more-button--215de";
export var marker = "index-module--marker--817bf";
export var noInstrumentation = "index-module--no-instrumentation--2dcea";
export var overline = "index-module--overline--08383";
export var productShot = "index-module--productShot--de6b8";
export var requirements = "index-module--requirements--d2659";
export var screenshotsSection = "index-module--screenshots-section--9802f";
export var sectionIcon = "index-module--section-icon--1ea99";
export var softwareVersions = "index-module--softwareVersions--663e8";
export var spaceGuys = "index-module--spaceGuys--ea6d0";
export var subtitle = "index-module--subtitle--ced8f";
export var titleSegment = "index-module--titleSegment--eaf87";
export var usePixieForSection = "index-module--usePixieForSection--3067c";
export var versionItem = "index-module--version-item--ef8ff";
export var versions = "index-module--versions--de5a8";
export var vizier = "index-module--vizier--45c2f";
export var white = "index-module--white--a8972";